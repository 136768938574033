import { Link } from "gatsby"
import React, { useState } from "react"

import logo from "../images/luisspainting.png"

const Nav = () => {
  const [active, setActive] = useState(false)

  return (
    <nav>
      <div className="container">
        <div className="grid">
          <div className="column-xs-7 column-md-3">
            <Link to="/" className="logo">
              <img src={logo} alt="Luis's Painting" />
            </Link>
          </div>
          <div className="column-xs-5 column-md-4 toggle-button">
            <div className="button-toggle">
              <button
                className="nav-button"
                aria-label="Menu"
                onClick={() => setActive(!active)}
                aria-expanded={active ? "true" : "false"}
              >
                <span className="menu icon" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          <div className="column-xs-12 column-md-9 mobile-menu">
            <ul className={active ? "main-nav active" : "main-nav"}>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/services">Our Services</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
