import React from "react"
import { Link } from "gatsby"

import logo from "../images/luisspainting.png"

const Footer = () => (
  <footer>
    <div className="container">
      <div className="grid">
        <div className="column-xs-12 column-md-5">
          <Link to='/' className="logo"><img src={logo} alt="Logo"/></Link>
          <p>Luis's Painting is a locally owned, licensed, and full service painting company with more than 20 years of experience serving New Jersey, New York, and Indiana areas.</p>
        </div>
        <div className="column-xs-12 column-md-3">
          <h3>Our Services</h3>
          <ul className="service-list">
            <li>Interior &amp; Exterior</li>
            <li>Power Washing</li>
            <li>Decks</li>
            <li>Commercial &amp; Residential</li>
            <li>Handyman Services</li>
          </ul>
        </div>
        <div className="column-xs-12 column-md-4">
          <h3>Contact Us</h3>
          <ul>
            <li>Luis's Painting</li>
            <li>Phone: <a href="tel:9736320560" className="page-link">(973) 632-0560</a></li>
            <li>Email: <a href="mailto:luisspainting@hotmail.com" className="page-link">luisspainting@hotmail.com</a></li>
          </ul>
        </div>
        <div className="column-xs-12">
          <p className="copyright">Copyright &copy; {new Date().getFullYear()}, Luis's Painting. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer